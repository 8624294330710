import React, { Component } from "react";
export default class ContactUs extends Component {
  render() {
    //let resumeData = this.props.resumeData;
    return (
      <section id="contact">
        <div className="row section-head">
          <h1 className="lead">Neváhajte nás kontaktovať</h1>
        </div>
        <div className="row">
          <div className="columns contact-details">
            <h2>Kontaktné údaje</h2>
            <p>Ing. Richard Nemec - majiteľ a konateľ</p>
            <p>
              Telefón: <a href="tel:+421949367209">+421 949 367 209</a>
            </p>
            <p>
              E-mail:
              <a href="mailto:info@vertical-office.sk">
                info@vertical-office.sk
              </a>
            </p>
            <p>
              WEB:
              <a href="www.vertical-office.sk" target="_blank">
                www.vertical-office.sk
              </a>
            </p>

            {/* <p className="address">
              <span>{resumeData.name}</span>
              <br />
              <span>{resumeData.address}</span>
            </p> */}
          </div>
          <div className="columns contact-details">
            <h2>Fakturačné údaje</h2>
            <p>Vertical Office s. r. o.</p>
            <p>Sídlo Mlynská 173/15 013 19 Zbyňov</p>
            <p>IČO 54904480 DIČ 2121814277</p>
            <p>IBAN: SK85 8330 0000 0028 0233 2540</p>
            Firma je zapísaná v Obchodnom registri Okresného súdu Žilina,
            oddiel: Sro, vložka č. 80494/L
            {/* <p className="address">
              <span>{resumeData.name}</span>
              <br></br>
              <span>{resumeData.address}</span>
            </p> */}
          </div>
        </div>
        {/* <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h4>Linked in :
                  {resumeData.linkedinId}
                </h4>
              </div>
            </aside>
          </div> */}
      </section>
    );
  }
}
