let resumeData = {
  imagebaseurl: "",
  name: "Výškové práce",
  role: "majiteľ a konateľ ",
  linkedinId: "Your LinkedIn Id",
  skypeid: "Your skypeid",
  cenovaPonuka:
    "Každá naša práca si vyžaduje osobitný prístup. To je hlavný dôvod, prečo nemáme fixný cenník. Radi vám však vypracujeme cenovú ponuku, ktorá je ZDARMA. Stačí odoslať email a my vás kontaktujeme. Vopred ďakujeme za prejavenú dôveru.",
  roleDescription:
    "Spoločnosť Vertical Office sa zaoberá prácami vo výškach a nad voľnou hladinou pomocou horolezeckej techniky a vysokozdvižných plošín v rámci celého Slovenska ale aj v zahraničí.",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com",
      className: "fa fa-linkedin",
    },
    {
      name: "facebook",
      url: "http://facebook.com",
      className: "fa fa-facebook",
    },
  ],
  aboutme:
    "Naše pracovné portfólio zastrešuje široký rámec činností v náročnom a nebezpečnom teréne ako aj na ťažko dostupných miestach. Dbáme na vysokú odbornosť a kvalitu našej práce, ako aj na bezpečnostné štandardy. Za firmou Vertical Office stojí tím profesionálnych výškových pracovníkov s dlhoročnými skúsenosťami ako na Slovensku, tak aj v zahraničí. Každý projekt je osobitne posudzovaný a nastavený na mieru zákazníka, aj preto sa stále snažíme o dlhodobé zlepšovanie, certifikácie či školenia. Pracujeme výhradne s certifikovaným výškarským vybavením a profesionálnym pracovným náradím, aby bola dosiahnutá čo najvyššia kvalita práce. ",
  address: "Slovensko",
  website: "",
  portfolio: [
    {
      name: "Montáž a servis telekomunikačných technológií",
      description:
        "Jednou z hlavných špecializácií firmy Vertical Office sú práve telekomunikačné zariadenia a systémy, v ktorých máme bohaté skúsenosti a široký záber. Počnúc výstavbou nosných konštrukcií, cez inštalácie nových site-ov, pridávanie nových technológií či upgrady existujúcich technológií.",
    },
    {
      name: "Fotovoltaické systémy a zelená energia",
      description:
        "Inštalácia solárnych panelov a fotovoltaických systémov, navrhnutie zariadení na mieru. Solárne systémy sú navrhnuté pre optimálne využitie solárnej energie a tým úsporu celkových nákladov.",
    },
    {
      name: "Maliarske a natieračské práce",
      list: [
        "Natieranie striech budov, kostolov a prevádzok",
        "Maľovanie fasád + ich sanácia a údržba",
        "Natieranie technologických konštrukcií a zariadení",
        "Maľovanie reklám",
      ],
    },
    {
      name: "Rekonštrukcie a servisné práce, údržba budov a priemyselných objektov",
      list: [
        "Oprava fasád a striech",
        "Demolačné práce, demontáž zariadení",
        "Tmelenie dilatačných škár, zatepľovanie na ťažko dostupných miestach",
        "Výmena osvetlenia, montáž bleskozvodov",
        "Elektroinštalácie ",
      ],
    },
    {
      name: " Čistiace práce a umývanie",
      list: [
        "Čistenie okien a sklenených plôch",
        "Čistenie fasád a striech budov",
        "Čistenie technologických zariadení",
        "Čistenie stropných konštrukcií",
        "Čistenie síl a zásobníkov",
      ],
    },
    {
      name: "Iné práce",
      list: [
        "Arboristika, rizikové spiľovanie stromov",
        "Sanácie svahov a odstraňovanie náletových drevín",
        "Montáž sietí a hrotov proti vtákom či hmyzu ",
        "Reklamné práce, vytváranie audiovizuálneho materiálu",
        "Ohliadky, revízie komínov, kontroly a iné",
        "Montáž a demontáž reklám, billboardov, osvetlení a pod",
        "Výstavba lanových dráh, zážitkových chodníkov a ferát",
        "Výstavba zabezpečovacích zariadení",
      ],
    },
  ],
  // work: [
  //   {
  //     CompanyName: "Some Company",
  //     specialization: "Some specialization",
  //     MonthOfLeaving: "Jan",
  //     YearOfLeaving: "2018",
  //     Achievements: "Some Achievements",
  //   },
  //   {
  //     CompanyName: "Some Company",
  //     specialization: "Some specialization",
  //     MonthOfLeaving: "Jan",
  //     YearOfLeaving: "2018",
  //     Achievements: "Some Achievements",
  //   },
  // ],
  // skillsDescription: "Your skills here",
  // skills: [
  //   {
  //     skillname: "HTML5",
  //   },
  //   {
  //     skillname: "CSS",
  //   },
  //   {
  //     skillname: "Reactjs",
  //   },
  // ],
  galery: [
    {
      name: "project1",
      description: "opis",
      imgurl: "images/galeria/project1.jpg",
    },
    {
      name: "project2",
      description: "opis",
      imgurl: "images/galeria/project2.jpg",
    },
    {
      name: "project3",
      description: "opis",
      imgurl: "images/galeria/project3.jpg",
    },
    {
      name: "project4",
      description: "opis",
      imgurl: "images/galeria/project4.jpg",
    },
    {
      name: "project5",
      description: "opis",
      imgurl: "images/galeria/project5.jpg",
    },
    {
      name: "project6",
      description: "opis",
      imgurl: "images/galeria/project6.jpg",
    },
    {
      name: "project7",
      description: "opis",
      imgurl: "images/galeria/project7.jpg",
    },
    {
      name: "project8",
      description: "opis",
      imgurl: "images/galeria/project8.jpg",
    },
  ],
  testimonials: [
    {
      description: "Individuálny prístup",
      name: "Some technical guy",
    },
    {
      description: "Najvyššia kvalita práce",
      name: "Some technical guy",
    },
    {
      description: "Jedinečné riešenia",
      name: "Some technical guy",
    },
  ],
};

export default resumeData;
